<template>
  <!-- 实时运单导入对话框 -->
  <el-dialog :title="upload.title" :visible.sync="value" width="400px" :before-close="cancel" append-to-body>
    <el-upload
      ref="upload"
      :limit="1"
      accept=".xlsx,.xls"
      :headers="upload.headers"
      :http-request='uploading'
      :action="upload.url + '?updateSupport=' + upload.updateSupport+'&code='+upload.code"
      :disabled="upload.isUploading"
      :on-progress="handleFileUploadProgress"
      :on-success="handleFileSuccess"
      :auto-upload="false"
      drag
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip text-center" slot="tip">
<!--        <div class="el-upload__tip" slot="tip">-->
<!--          <el-checkbox v-model="upload.updateSupport" /> 是否更新已经存在的运单数据-->
<!--        </div>-->
        <span>仅允许导入xls、xlsx格式文件。</span>
        
      </div>
    </el-upload>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="importLoading" @click="submitFileForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
const { VUE_APP_API_HOST, VUE_APP_API_QIYE_HOST } = process.env;
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
  name: "batchDelete",
  components: {},
  props: {
    value: Boolean
  },
  data() {
     var lcode = sessionStorage.getItem('login_name')
    return {
      importLoading: false,
      failureMsg:'',
      // 用户导入参数
      upload: {
        // 弹出层标题（用户导入）
        title: "作废运单导入",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的运单数据
        updateSupport: 0,
        // 设置上传的请求头部
     
        // 上传的地址
        url: VUE_APP_API_QIYE_HOST+"/shipping_info/batchDelete",
        code:lcode
      },
      // 遮罩层
      loading: false
    };
  },
  created() {
  },
  methods: {
    // 取消按钮
    cancel() {
      this.$emit("input", false);
    },
    /** 下载模板操作 */
   uploading(data){
  let file = data.file
  let params = new FormData()
  params.append('file',file)
   let header = {
        'content-type': 'application/json',
        'token': Cookies.get('token') || '',
    };
  axios({
    method:'POST',
    url: VUE_APP_API_QIYE_HOST+"/shipping_info/batchDelete",
    data:params,
    headers: header,
    responseType:'blob'
  })
  .then(response=>{
  
   this.importLoading=false;
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
   this.$alert(response.msg, "批量作废运单成功", { dangerouslyUseHTMLString: true });
     
})
  .catch(error=>{
  console.log(error)
})
},

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.importLoading=false;
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.msg, "导入结果", { dangerouslyUseHTMLString: true });
      this.cancel();


    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
      this.importLoading=true;
    }
  }
};
</script>
