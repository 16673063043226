var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.upload.title,
        visible: _vm.value,
        width: "400px",
        "before-close": _vm.cancel,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.value = $event
        }
      }
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            limit: 1,
            accept: ".xlsx,.xls",
            headers: _vm.upload.headers,
            "http-request": _vm.uploading,
            action:
              _vm.upload.url +
              "?updateSupport=" +
              _vm.upload.updateSupport +
              "&code=" +
              _vm.upload.code,
            disabled: _vm.upload.isUploading,
            "on-progress": _vm.handleFileUploadProgress,
            "on-success": _vm.handleFileSuccess,
            "auto-upload": false,
            drag: ""
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")])
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip text-center",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [_c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")])]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.importLoading },
              on: { click: _vm.submitFileForm }
            },
            [_vm._v("确 定")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }