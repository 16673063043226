<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true"  v-show="showSearch" class="query-form">
      <el-form-item label="设备号" prop="device">
        <el-input
          v-model="queryParams.device"
          placeholder="请输入设备号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="运单号" prop="shippingNoteNumber">
        <el-input
          v-model="queryParams.shippingNoteNumber"
          placeholder="请输入运单号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

<el-form-item label="创建日期" prop="createDate">
       <el-date-picker
                  :default-value="defaultValue"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="queryParams.timerScope"
                ></el-date-picker>

</el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        
      </el-form-item>
    </el-form>

    <el-table 
      border
      stripe
      v-loading="loading" 
      ref="shippingListTable" 
      :data="shippingList" 
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column label="序号" fixed align="center" type="index" />
      
      <el-table-column label="运单号" align="center" prop="shippingNoteNumber" width="250">
      </el-table-column>
      <el-table-column label="设备号" align="center" prop="device" width="200">
      
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime" width="150">
        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
      </el-table-column>
      
      <el-table-column label="回调路径" align="center" prop="url"  width="120"/>
      <el-table-column label="回调内容" align="center" prop="json"  width="300"/>
      <el-table-column label="回调返回状态" align="center" prop="response" width="100" >
      </el-table-column>
      
   
    </el-table>

    <pagination
      v-show="total>=0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      :page-sizes="[10, 20, 50, 100, 200,500, 1000, 2000]"
      @pagination="getList"
    >
      <template v-slot:end>
        <el-button
            type="warning"
            icon="el-icon-download"
            size="small"
            :loading="exportLoading"
            @click="handleExportLocal"
          >导出当前页
          </el-button>
      </template>
    </pagination>

    <!--查看轨迹-->
    <baidu-map-track ref="mapTrack" v-model="dialogMapTrackVisible"></baidu-map-track>
       <batch-Delete v-model="open11"  ref="batchDelete"/>
  </div>
</template>

<script>
import { listShipping} from "@/api/schedule/pushlist";
import BaiduMapTrack from './components/BaiduMap/track';
import Pagination from "./components/pagination.vue"
import { formateDate } from '@/libs/util'
import DictTag from "./components/dictTag.vue"
import batchDelete from './components/batchDelete';

export default {
  name: "ShippingList",
  components: {Pagination, BaiduMapTrack, DictTag,batchDelete},
  props: {
    number: String
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      open11: false,
      // 设备围栏关联表表格数据
      shippingList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device: null,
        driverPhone: null,
        shippingStatus: null,
        shippingNoteNumber: null,
        transportBillNumber:null,
        timerScope:[],
      },
      shippingStatuses: [
        //0 未开始 1:进行中(开始围栏已触发) 2:已触发结束(结束围栏已触发) 3: 非触发结束 4: 手动作废
        { label: '未开始', value: 0, type: 'info' },
        { label: '进行中(开始围栏已触发)', value: 1, type: 'primary' },
        { label: '已触发结束(结束围栏已触发)', value: 2, type: 'success' },
        { label: '非触发结束', value: 3, type: 'warning' },
        { label: '手动作废', value: 4, type: 'danger' }
      ],
      dialogMapTrackVisible: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      if (this.number) {
        this.queryParams.shippingNoteNumber = this.number
      }
      this.getList()
    },
    /** 查询设备围栏关联表列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listShipping(form).then(response => {
        this.shippingList = response.data.list;
        this.total = response.data.count;
      }).finally(()=> {
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 导出按钮操作 */
    handleExportLocal() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出当前页的运单数据项？').then(() => {
        this.exportLoading = true;
        let fileName = '生成运单数据_' + Object.keys(this.queryParams).filter(k => !!this.queryParams[k] && this.queryParams[k] !== null).map(k => k + '_' + this.queryParams[k]).join('-') + '_' + formateDate(new Date().getTime());
        return exportShippingLocal(this.$refs.shippingListTable.$el, fileName, 1);
      }).then(response => {
        this.$Message.success(response.msg);
      }).catch(error => {
        this.$Message.error(error.message || error);
      }).finally(() => {
        this.exportLoading = false;
      })
    },
    formatStatus(status) {
      let labels = this.shippingStatuses.filter(s => s.value === status).map(s => s.label)
      return labels.length ? labels[0] : `未知（${status}）`
    },
    toDevice(row) {
      this.$router.push({path: '/schedule/device', query: {device: row.device}})
    },
    toVehicle(row) {
      this.$router.push({path: '/schedule/device', query: {vehicle: row.vehicleNumber}})
    },
    handleCommand(command, row) {
      switch(command) {
        case 'toDevice':
          this.toDevice(row);
          return;
        case 'toVehicle':
          this.toVehicle(row);
          return;
      }
    },
    showTrack(row) {
      this.dialogMapTrackVisible = true;
      this.$nextTick(() => {
        this.$refs.mapTrack.init(row);
      });
    },
  }
};
</script>
